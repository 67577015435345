import { useState } from "react";
import { Dialog, DialogPanel, PopoverGroup } from "@headlessui/react";
import {
    Bars3Icon,
    RectangleStackIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import { Link } from "@inertiajs/react";
import ApplicationLogo from "../ApplicationLogo";
import { Button } from "./button";
import { FormattedMessage } from "react-intl";

export default function LandingBar({ auth }) {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    return (
        <header className="bg-white">
            <nav
                aria-label="Global"
                className="flex items-center justify-between p-6 mx-auto max-w-7xl lg:px-8"
            >
                <div className="flex lg:flex-1">
                    <a href={route("welcome")} className="-m-1.5 p-1.5">
                        <span className="sr-only">MyPlace</span>
                        <ApplicationLogo className="w-auto h-8" />
                    </a>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        onClick={() => setMobileMenuOpen(true)}
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon aria-hidden="true" className="w-6 h-6" />
                    </button>
                </div>
                <PopoverGroup className="hidden lg:flex lg:gap-x-12">
                    <a
                        href={route("welcome")}
                        className="text-sm font-semibold leading-6 text-gray-900"
                    >
                        <FormattedMessage id="outer.pages.nav.option.home" />
                    </a>
                    <a
                        href={route("landlords")}
                        className="text-sm font-semibold leading-6 text-gray-900"
                    >
                        <FormattedMessage id="outer.pages.nav.option.landlords" />
                    </a>
                    <a
                        href={route("about")}
                        className="text-sm font-semibold leading-6 text-gray-900"
                    >
                        <FormattedMessage id="outer.pages.nav.option.about" />
                    </a>
                </PopoverGroup>
                <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                    {auth.user ? (
                        <Button color="teal" href={route("home")}>
                            <FormattedMessage
                                id="outer.pages.nav.button.listings"
                                values={{
                                    icon: <RectangleStackIcon />,
                                }}
                            />
                        </Button>
                    ) : (
                        <div className="flex gap-4">
                            <Button outline href={route("login")}>
                            <FormattedMessage id="outer.pages.nav.button.login" />
                            </Button>
                            <Button color="teal" href={route("register")}>
                            <FormattedMessage id="outer.pages.nav.button.register" />
                            </Button>
                        </div>
                    )}
                </div>
            </nav>
            <Dialog
                open={mobileMenuOpen}
                onClose={setMobileMenuOpen}
                className="lg:hidden"
            >
                <div className="fixed inset-0 z-10" />
                <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full px-6 py-6 overflow-y-auto bg-white sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <a href="#" className="-m-1.5 p-1.5">
                            <span className="sr-only">MyPlace</span>
                            <ApplicationLogo className="w-auto h-8" />
                        </a>
                        <button
                            type="button"
                            onClick={() => setMobileMenuOpen(false)}
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon aria-hidden="true" className="w-6 h-6" />
                        </button>
                    </div>
                    <div className="flow-root mt-6">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="py-6 space-y-2">
                                <a
                                    href={route("welcome")}
                                    className="block px-3 py-2 -mx-3 text-base font-semibold leading-7 text-gray-900 rounded-lg hover:bg-gray-50"
                                >
                                    <FormattedMessage id="outer.pages.nav.option.home" />
                                </a>
                                <a
                                    href={route("landlords")}
                                    className="block px-3 py-2 -mx-3 text-base font-semibold leading-7 text-gray-900 rounded-lg hover:bg-gray-50"
                                >
                                    <FormattedMessage id="outer.pages.nav.option.landlords" />
                                </a>
                                <a
                                    href={route("about")}
                                    className="block px-3 py-2 -mx-3 text-base font-semibold leading-7 text-gray-900 rounded-lg hover:bg-gray-50"
                                >
                                    <FormattedMessage id="outer.pages.nav.option.about" />
                                </a>
                            </div>
                            <div className="py-6">
                                {auth.user ? (
                                    <Button color="teal" href={route("home")}>
                                        <FormattedMessage id="outer.pages.nav.button.listings" 
                                        values={{ 
                                            icon: <RectangleStackIcon />,
                                         }}/>
                                    </Button>
                                ) : (
                                    <>
                                        <div className="flex gap-4">
                                            <Button
                                                outline
                                                href={route("login")}
                                            >
                                                <FormattedMessage id="outer.pages.nav.button.login" />
                                            </Button>
                                            <Button
                                                color="teal"
                                                href={route("register")}
                                            >
                                                <FormattedMessage id="outer.pages.nav.button.register" />
                                            </Button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </header>
    );
}
